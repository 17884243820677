<template>
    <div class="hero">
        <img :src="image" alt="." />
    </div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            required: true,
            default: '../img/slider-1.jpg',
        }
    }
}
</script>