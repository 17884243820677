<template>
	<div>
		<h1>{{ title }}</h1>
		<p class="lead" v-if="desc">{{ desc }}</p>
	</div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: "Page"
        },
        desc: {
            type: String,
        }
    }
}
</script>