import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: () => import("../views/Home.vue"),
	},
	{
		path: "/about-us",
		component: () => import("../views/About/Index"),
		children: [
			{
				path: '',
				component: () => import("../views/About/Introduction"),
			},
			{
				path: 'board-of-directors',
				component: () => import("../views/About/BoardPage"),
			},
			{
				path: 'mision-vision',
				component: () => import("../views/About/Mission"),
			},
			{
				path: 'belief-statement',
				component: () => import("../views/About/Belief"),
			},
			{
				path: 'values',
				component: () => import("../views/About/ValuesPage"),
			},
			{
				path: 'facilities',
				component: () => import("../views/About/FacultiesPage"),
			},

		]
	},
	{
		path: "/admissions",
		component: () => import("../views/Admissions/Index"),
		children: [
			{
				path: "",
				component: () => import("../views/Admissions/Info"),
			},
			{
				path: "apply",
				component: () => import("../views/Admissions/Apply"),
			},
		]
	},
	{
		path: "/campus-life",
		component: () => import("../views/CampusLife/Index"),
		children: [
			{
				path: "",
				component: () => import("../views/CampusLife/Info"),
			},
			{
				path: "apply",
				component: () => import("../views/CampusLife/Apply"),
			},
		]
	},
	{
		path: "/contact-us",
		component: () => import("../views/ContactUs.vue"),
	},

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to,from,savedPosition) {

		// if (to.hash) {
		// 	return {
		// 		selector: to.hash
		// 	};
		// }

		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0,y: 0 };
		}


	}
})


export default router
