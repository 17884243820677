<template>
    <div>
		<AppHeader />
		<router-view :key="$route.path" />
		<AppFooter />
    </div>
</template>

<script>
export default {
    data(){
		return {}
	},
	components: {
		AppHeader: () => import("@/components/Header.vue"),
		AppFooter: () => import("@/components/Footer.vue"),
	}
}
</script>
