import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "../public/css/style.scss"
import "../public/css/responsive.scss"

import Hero from "./components/Hero";
Vue.component("Hero",Hero)

import MenuAbout from "./components/Menu/About";
Vue.component("MenuAbout",MenuAbout)

import MenuAdmissions from "./components/Menu/Admissions";
Vue.component("MenuAdmissions",MenuAdmissions)

import Heading from "./components/Heading";
Vue.component("Heading",Heading)

import MenuInner from "./components/MenuInner.vue";
Vue.component("MenuInner",MenuInner)


import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/auth"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyA1wFxlPLD-VeT4Gq_xqgNAn1BQRNfmPBA",
	authDomain: "east-bridge-production.firebaseapp.com",
	projectId: "east-bridge-production",
	storageBucket: "east-bridge-production.appspot.com",
	messagingSenderId: "873697619059",
	appId: "1:873697619059:web:52ff7c18bf9a6b653d8e06",
	measurementId: "G-GC9TEFQQL9"
};

firebase.initializeApp(firebaseConfig);


import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps,{
	load: {
		key: 'AIzaSyAJJDj8mV6N_MZA9KXTUnt6nLWjLlI5Dkg',
		libraries: 'places',
	}
});

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
